<!-- AuthModal.vue -->
<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card class="tw-rounded-lg">
      <v-card-title class="tw-flex tw-flex-col tw-items-center tw-bg-gray-100 tw-py-6">
        <img src="../../../public/img/logo.png" alt="Company Logo" class="tw-w-32 tw-mb-4" />
        <span class="tw-text-xl tw-font-semibold c-blue-text">Input the following to Proceed</span>
      </v-card-title>
      <v-card-text class="tw-pt-6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="authForm.prospectName"
            label="Name"
            outlined
            dense
            :rules="[v => !!v || 'Name is required']"
            required
          ></v-text-field>
          <v-text-field
            v-model="authForm.prospectTelephone"
            label="Phone Number"
            outlined
            dense
            :rules="[
              v => !!v || 'Phone number is required',
              v => /^[0-9]{10}$/.test(v) || 'Phone number must be 10 digits'
            ]"
            required
          ></v-text-field>
          <v-text-field
            v-model="authForm.prospectEmail"
            label="Email"
            outlined
            dense
            :rules="[
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ]"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="tw-pb-6">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          :loading="loading"
          @click="submit"
          :disabled="!valid"
          class="tw-px-8"
        >
          Submit
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuthModal',
  props: {
    show: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      valid: true,
      authForm: {
        prospectName: '',
        prospectTelephone: '',
        prospectEmail: '',
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.authForm);
      }
    },
  },
};
</script>
