var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"tw-rounded-lg"},[_c('v-card-title',{staticClass:"tw-flex tw-flex-col tw-items-center tw-bg-gray-100 tw-py-6"},[_c('img',{staticClass:"tw-w-32 tw-mb-4",attrs:{"src":require("../../../public/img/logo.png"),"alt":"Company Logo"}}),_c('span',{staticClass:"tw-text-xl tw-font-semibold c-blue-text"},[_vm._v("Input the following to Proceed")])]),_c('v-card-text',{staticClass:"tw-pt-6"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Name","outlined":"","dense":"","rules":[v => !!v || 'Name is required'],"required":""},model:{value:(_vm.authForm.prospectName),callback:function ($$v) {_vm.$set(_vm.authForm, "prospectName", $$v)},expression:"authForm.prospectName"}}),_c('v-text-field',{attrs:{"label":"Phone Number","outlined":"","dense":"","rules":[
            v => !!v || 'Phone number is required',
            v => /^[0-9]{10}$/.test(v) || 'Phone number must be 10 digits'
          ],"required":""},model:{value:(_vm.authForm.prospectTelephone),callback:function ($$v) {_vm.$set(_vm.authForm, "prospectTelephone", $$v)},expression:"authForm.prospectTelephone"}}),_c('v-text-field',{attrs:{"label":"Email","outlined":"","dense":"","rules":[
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],"required":""},model:{value:(_vm.authForm.prospectEmail),callback:function ($$v) {_vm.$set(_vm.authForm, "prospectEmail", $$v)},expression:"authForm.prospectEmail"}})],1)],1),_c('v-card-actions',{staticClass:"tw-pb-6"},[_c('v-spacer'),_c('v-btn',{staticClass:"tw-px-8",attrs:{"color":"secondary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" Submit ")]),_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }