<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <top-card current="PersonalDetails" cover="Elimu Plan" :amount="5"
        >Kindly provide some additional details before you proceed</top-card
      >

      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title icon="/images/icons/elimu-b.png"
          >Personal Details
        </card-title>
        <back-nav to="AgentGuide" :page-change="true"/>
        <v-form v-model="isFormValid" ref="form">
          <div class="tw-px-4 md:tw-px-12 tw-mt-4 md:tw-mt-10">
            <div class="tw-w-full">
              <h3
                class="
                  tw-text-base
                  md:tw-text-lg
                  tw-px-0
                  md:tw-px-6
                  tw-mt-3
                  md:tw-mt-10
                "
              >
                Please provide information of the
                <span class="c-blue-text"> Life assured </span>
                before proceeding.
              </h3>
            </div>
            <div>
              <div class="grid grid-cols-3 my-6 tw-px-0 md:tw-px-6">
                <h4 class="c-blue-text">Gender</h4>
                <v-radio-group
                  id="gender"
                  v-model="form.gender"
                  :rules="[required('Gender')]"
                  row
                >
                  <v-radio
                    label="Male"
                    value="MALE"
                    class="tw-mt-0 md:tw-mt-2 tw-pr-10"
                  ></v-radio>
                  <v-radio
                    label="Female"
                    value="FEMALE"
                    class="tw-mt-0 md:tw-mt-2"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-items-end">
                <v-select
                  id="title"
                  class="md:tw-mx-5"
                  :items="title"
                  label="Title"
                  v-model="form.title"
                ></v-select>
                <v-text-field
                  id="surname"
                  v-model="form.surname"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Surname"
                  type="text"
                  :rules="[required('Surname')]"
                ></v-text-field>
                <v-text-field
                  id="otherNames"
                  v-model="form.othernames"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Other Names"
                  type="text"
                  :rules="[required('Other Names')]"
                ></v-text-field>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.dateOfBirth"
                      persistent-hint
                      readonly
                      label="Date Of Birth"
                      v-bind="attrs"
                      v-on="on"
                      class="md:tw-mx-5"
                      :rules="[required('Date of Birth')]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    id="dateOfBirth"
                    :active-picker.sync="activePicker"
                    v-model="form.dateOfBirth"
                    no-title
                    @input="menu = false"
                    :max="givenYearsAgo(18)"
                    :min="givenYearsAgo(65)"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
                <v-select id="nationality" v-model="form.nationality" class="md:tw-mx-5" :items="nationalities"
                  label="Nationality" :rules="[required('Nationality')]">
                </v-select>
                <vue-tel-input-vuetify
                  id="mobileNumber"
                  v-bind="phoneNumberBindProps"
                  v-model="phoneNumber"
                  class="my-2 tw-px-0 md:tw-px-6"
                  :rules="[required('Mobile No.')]"
                  label="Mobile No."
                />
                <v-text-field
                  id="email"
                  v-model="form.email"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Email"
                  type="text"
                  :rules="[required('Email'), emailFormat()]"
                ></v-text-field>
                <v-select
                  id="maritalStatus"
                  v-model="form.maritalStatus"
                  class="md:tw-mx-5"
                  :items="maritalStatusList"
                  label="Marital Status"
                  :rules="[required('Marital Status')]"
                ></v-select>
                <v-select
                  id="occupation"
                  v-model="form.occupation"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Occupation"
                  :items="occupationList"
                  type="text"
                  :rules="[required('occupation')]"
                ></v-select>
                <v-text-field
                  v-if="otherOccupation"
                  id="otherOccupation"
                  v-model="writeOccupation"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Write Occupation "
                  type="text"
                  :rules="[required('Occupation')]"
                ></v-text-field>
                <v-text-field
                  id="nameOfEmployer"
                  v-model="form.nameOfEmployer"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Name of Employer / Business "
                  type="text"
                  :rules="[required('Name Of Employer / Business')]"
                ></v-text-field>
                <v-text-field
                  id="natureOfBusiness"
                  v-model="form.natureOfBusiness"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Nature of Business "
                  type="text"
                  :rules="[required('Nature of Business')]"
                ></v-text-field>
                <v-text-field
                  id="residence"
                  v-model="form.residence"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Residential Address"
                  type="text"
                  :rules="[required('Residential Address')]"
                ></v-text-field>
                <v-text-field
                  id="kraPinNumber"
                  v-model="form.kraPinNumber"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="KRA PIN"
                  type="text"
                  :rules="[required('KRA Pin Number'), kraPinFormat()]"
                ></v-text-field>
                <v-text-field
                  id="idNumber"
                  v-model="form.idNumber"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="ID/Passport Number"
                  type="text"
                  :rules="[required('ID/Passport Number')]"
                ></v-text-field>
                <div class="">
                  <label>ID/Passport Image Upload (Front Side)</label>
                  <file-upload
                    v-model="images.image1"
                    :added-image="images.image1"
                    :rules="[required('ID required')]"
                    name="photo-1"
                  />
                </div>
                  <div><label>ID/Passport Image Upload (Back Side)</label>
                  <file-upload
                  v-model="images.image2"
                    :added-image="images.image2"
                    :rules="[required('ID required')]"
                    name="photo-2"
                  /></div>
                  <div><label>KRA PIN Certificate Upload</label>
                  <file-upload
                  v-model="images.image3"
                    :added-image="images.image3"
                    :rules="[required('KRA Certificate')]"
                    name="photo-3"
                  /></div>
              </div>
              <div
              v-if="sumAssured >= sourceOfFundsLimit || contributionIPP >= sourceOfFundsLimit"
              class="tw-w-full">
                <h3
                  class="
                    c-blue-text c-title
                    tw-text-base
                    md:tw-text-lg
                    tw-px-2
                    md:tw-px-8
                    tw-mt-3
                    md:tw-mt-10
                  "
                >
                  Please select source of funds
                </h3>
                <div class="tw-mt-2 tw-ml-8">
                    <v-checkbox id="check" label="Source of Funds" v-model="isChecked" :rules="[check()]"></v-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="container tw-w-full tw-py-3 md:tw-py-6">
            <div class="tw-flex tw-flex-wrap">
              <v-btn
                class="secondary tw-w-64 mx-auto tw-my-8"
                large
                id="proceed"
                @click="proceed"
                :loading="pageLoading"
                :disabled="!isFormValid"
                >Continue</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card>

      <v-dialog v-model="isSource" persistent>
        <v-card
        elevation="2"
        class="tw-px-2 md:tw-px-6 tw-mt-5 md:tw-mt-10 mx-auto"
        max-width="600px"
      >
        <card-title :icon="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')">
          Source of Funds
        </card-title>
        <div class="tw-w-full tw-text-center">
          <h2 class="tw-font-semibold pt-7 pb-5">{{ scanObject(currentProduct, 'title') }}</h2>
          <p class="pb-5">
            Please confirm the source of funds for this investment
          </p>
          <v-form class="pt-5" ref="form" v-model="isValid">
              <v-select
                id="sourceOfFunds"
                v-model="sourceOfFunds"
                class="md:tw-mx-5"
                :items="sourceOfFundsList"
                label="Source Of Funds"
                :rules="[required('Source Of Funds')]"
              ></v-select>
          </v-form>
        </div>

        <div class="container tw-w-full md:tw-py-6">
          <div class="tw-flex tw-flex-wrap">
            <v-btn
            id="confirmSource"
              class="secondary tw-w-64 mx-auto tw-my-8"
              @click="confirmSource"
              large
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </div>
        </div>
      </v-card>
      </v-dialog>

      <v-dialog v-model="policyOwnerDialog" max-width="530px">
        <v-card>
          <v-container>
            <v-form class="mt-5" v-model="isClientPolicyOwnerFormValid">
              <v-card-title class="text-center">
                <h3 class="mx-auto primary--text">
                  Please Confirm Policy Owner
                </h3>
              </v-card-title>
              <div class="grid grid-cols-12 my-6 tw-px-0 md:tw-pl-32">
                <h4>Is the client the policy owner?</h4>
                <v-radio-group
                  id="lifeAssured"
                  v-model="isClientPolicyOwner"
                  :rules="[required('Is client policy owner')]"
                  row
                >
                  <v-radio
                    label="Yes"
                    value="Yes"
                    class="tw-mt-0 md:tw-mt-2 tw-pr-10"
                  ></v-radio>
                  <v-radio
                    label="No"
                    value="No"
                    class="tw-mt-0 md:tw-mt-2"
                  ></v-radio>
                </v-radio-group>
              </div>
              <v-card-actions>
                <div class="ma-auto pb-2">
                  <v-row align="center" justify="space-around">
                    <v-btn
                      outlined
                      @click="policyOwnerDialog = false"
                      color="primary"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="secondary"
                      class="ma-5 pa-4"
                      :disabled="!isClientPolicyOwnerFormValid"
                      @click="confirmClientPolicyOwner"
                      :loading="loadingConfirmation"
                    >
                      Confirm
                    </v-btn>
                  </v-row>
                </div>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import FileUpload from '@/components/FileUpload';
import BackNav from '../BackNav';
import clientInfoMixins from '@/mixins/clientInfoMixins';
import validations from '@/utils/validations';
import { formatDate, givenYearsAgo } from '@/utils/time';
import { nationalityList } from '@/utils/nationalities';
import { SOURCE_FUNDS_LIMIT } from '../../utils/const';

export default {
  name: 'PersonalDetails',
  metaInfo: {
    title: 'Buy Cover - Personal Details',
  },
  components: {
    CardTitle,
    TopCard,
    FileUpload,
    BackNav,
  },
  mixins: [clientInfoMixins],

  data() {
    return {
      savedQuoteClientData: {},
      clientIdentity: '',
      date: '',
      title: ['Mr', 'Mrs', 'Miss', 'Prof', 'Doc'],
      maritalStatusList: ['Single', 'Married', 'Divorced', 'Widowed'],
      nationalities: nationalityList(),
      occupation: null,
      occupationList: [
        'ACCOUNTANT', 'ADMINISTRATOR', 'BANKER', 'BUSINESS PERSON', 'CIVIL SERVANT', 'DRIVER', 'ENGINEER', 'FARMER', 'HEALTH/MEDICAL PRACTITIONER',
        'INSURANCE OFFICER', 'LAWYER', 'MECHANIC', 'PILOT', 'POLICE OFFICER', 'SALES PERSON/MARKETER',
        'SECURITY OFFICER', 'SOLDIER', 'STUDENT', 'TEACHER/LECTURER', 'TECHNICIAN', 'Other',
      ],
      writeOccupation: null,
      loading: false,
      loadingConfirmation: false,
      pageLoading: false,
      phoneNumber: null,
      isClientPolicyOwnerFormValid: false,
      isValid: false,
      isFormValid: false,
      isSource: false,
      isChecked: false,
      menu: false,
      isClientPolicyOwner: null,
      form: {
        surname: '',
        othernames: '',
        email: '',
        dateOfBirth: '',
        nationality: 'Kenyan',
        sourceOfFunds: '',
      },
      images: {
        image1: {},
        image2: {},
        image3: {},
      },
      ...validations,
      policyOwnerDialog: false,
      activePicker: null,
      otherOccupation: false,
      sourceOfFundsList: [
        'Investment Income',
        'Gift or Inheritance',
        'Loan',
        'Sale of Investment Porfolio/Property',
        'Pension',
        'Salary/Savings',
      ],
      sourceOfFunds: null,
      sourceOfFundsLimit: SOURCE_FUNDS_LIMIT,
      sumAssured: null,
      contributionIPP: null,
    };
  },
  watch: {
    isChecked() {
      if (this.isChecked === true) {
        this.isSource = true;
      } else {
        this.isSource = false;
      }
    },
    date() {
      this.form.coverFrom = this.formatDate(this.date);
    },
    phoneNumber() {
      this.form.mobileNumber = this.phoneNumber.replace(/\s/g, '');
    },
    occupation() {
      this.form.occupation = this.occupation;
      if (this.occupation && this.occupation === 'Other') {
        this.otherOccupation = true;
      } else {
        this.otherOccupation = false;
      }
    },
    writeOccupation() {
      this.form.occupation = this.writeOccupation;
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'capture_personal_details_page_visited_by_agent', {
        event_category: 'capture personal details component created',
        event_label: 'capture personal details component created',
      });
    }
  },
  mounted() {
    this.getClientDetails();
  },
  computed: {
    computedDateFormatted() {
      return this.form.coverFrom || this.formatDate(this.date);
    },
    ...mapGetters('quote', ['policyOwner']),
    ...mapState({
      clientCachedData: (state) => state.userPersonalData.personalData,
      productData: (state) => state.quote.productCode,
      productTerm: (state) => state.quote.productTerms,
      clientCodeState: (state) => state.quote.clientCode,
      quoteCodeState: (state) => state.quote.quoteCode,
      clientIdState: (state) => state.quote.clientId,
      quoteSumState: (state) => state.userPersonalData.personalData.amount,
    }),
  },
  methods: {
    cachedInfo() {
      const fullname = this.savedQuoteClientData.clientName;
      const fullNameSplit = fullname.indexOf(' ');
      this.form.surname = fullname.slice(0, fullNameSplit);
      this.form.othernames = fullname.slice(fullNameSplit + 1);
      this.form = {
        title: this.savedQuoteClientData.clientTitle ? this.savedQuoteClientData.clientTitle : '',
        gender: this.savedQuoteClientData.clientGender ? this.savedQuoteClientData.clientGender : '',
        othernames: this.form.othernames ? this.form.othernames : '',
        email: this.savedQuoteClientData.clientEmail ? this.savedQuoteClientData.clientEmail : '',
        surname: this.form.surname ? this.form.surname : '',
        dateOfBirth: this.savedQuoteClientData.clientDateOfBirth ? this.savedQuoteClientData.clientDateOfBirth : '',
        nationality: 'Kenyan',
        idNumber: this.savedQuoteClientData.clientIdNumber ? this.savedQuoteClientData.clientIdNumber : '',
        kraPinNumber: this.savedQuoteClientData.clientPIN ? this.savedQuoteClientData.clientPIN : '',
        residence: this.savedQuoteClientData.clientPhysicalAddress ? this.savedQuoteClientData.clientPhysicalAddress : '',
        natureOfBusiness: this.savedQuoteClientData.natureOfBusiness ? this.savedQuoteClientData.natureOfBusiness : '',
        nameOfEmployer: this.savedQuoteClientData.clientEmployer ? this.savedQuoteClientData.clientEmployer : '',
        maritalStatus: this.savedQuoteClientData.clientMaritalStatus ? this.savedQuoteClientData.clientMaritalStatus : '',
        occupation: this.savedQuoteClientData.clientOccupation ? this.savedQuoteClientData.clientOccupation : '',
      };
      this.phoneNumber = (this.savedQuoteClientData.clientTelephone ? this.savedQuoteClientData.clientTelephone : '');
    },
    proceed() {
      this.loadingConfirmation = true;
      this.$store.commit('quote/lifeAssuredInfo', this.form);
      this.$store.commit('quote/lifeAssuredImages', this.images);
      this.policyOwnerDialog = true;
      this.loadingConfirmation = false;
    },
    getClientDetails() {
      this.pageLoading = true;
      axios
        .get(
          `/lmsagent/quotations/findQuoteSummary?Quote%20Code=${this.quoteCodeState}`,
        )
        .then((res) => {
          this.savedQuoteClientData = res.data.data.client;
          this.sumAssured = res.data.data.sumAssured;
          this.contributionIPP = res.data.data.totalContribution;
          this.cachedInfo();
          this.pageLoading = false;
        });
    },
    confirmSource() {
      if (this.sourceOfFunds) {
        this.$store.commit('quote/sourceOfFundsInfo', this.sourceOfFunds);
        this.isSource = false;
      } else {
        this.isSource = true;
      }
    },
    confirmClientPolicyOwner() {
      this.loading = true;
      if (this.isClientPolicyOwner === 'Yes') {
        this.$store.commit('quote/policyOwnerInfo', this.form);
        this.$emit('to-beneficiaries');
      } else {
        this.$emit('to-owner-details');
      }
      this.loading = false;
    },
    formatDate,
    givenYearsAgo,
  },
};
</script>
