<template>
  <v-card class="mx-auto tw-px-2 md:tw-px-4 tw-py-2 md:tw-py-4">
    <h3
      class="c-blue-text c-title tw-text-base md:tw-text-xl tw-px-0 md:tw-px-10"
    >
      <slot></slot>
      <hr class="tw-mt-4" />
    </h3>
    <div class="tw-w-full tw-flex">
      <div
        class="
          tw-w-full tw-flex tw-justify-center tw-my-5
          md:tw-my-8
          tw-flex-wrap
        "
      >
        <div class="tw-flex tw-px-2 md:tw-px-10 tw-items-center">
          <img
            :src="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')"
            class="tw-h-12 lg:tw-h-24 tw-mr-2 lg:tw-mr-4"
            alt="car icon"
          />
          <h3
            class="
              c-gray-text c-title
              tw-text-base
              md:tw-text-xl
              tw-px-2
              md:tw-px-8
            "
          >
            <span>
              {{ scanObject(currentProduct, 'title') }}
            </span
            ><br />
            <span class="text--primary">APA LIFE</span>
          </h3>
        </div>
        <div
          class="
            tw-px-0
            md:tw-px-10
            tw-mt-5
            md:tw-mt-0
            tw-text-center
            lg:tw-text-left
          "
        >
          <h4 class="c-blue-text tw-font-bold tw-text-sm">Terms</h4>
          <h3 class="c-light-blue-text title-font tw-text-4xl md:tw-text-5xl">
            {{ termState | formatNum }}
          </h3>
        </div>
      </div>
    </div>
    <div v-if="!progressHidden" class="tw-w-full tw-px-0 md:tw-px-8">
      <v-stepper alt-labels>
        <v-stepper-header>
          <v-stepper-step step="1" :complete="progress > 0">
            Personal Details
          </v-stepper-step>

          <v-divider :color="progress > 0 ? 'secondary' : ''"></v-divider>

          <v-stepper-step step="2" :complete="progress > 25">
            Add Beneficiary
          </v-stepper-step>

          <v-divider :color="progress > 25 ? 'secondary' : ''"></v-divider>

          <v-stepper-step step="3" :complete="progress > 50">
            Health Questionaire
          </v-stepper-step>

          <v-divider :color="progress > 50 ? 'secondary' : ''"></v-divider>

          <v-stepper-step step="4" :complete="progress > 75">
            Proposal Summary
          </v-stepper-step>

          <v-divider :color="progress > 75 ? 'secondary' : ''"></v-divider>

          <v-stepper-step :complete="progress === 100" step="5">
            Make Payment
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>
  </v-card>
</template>

<script>
import helpers from '@/utils/helpers';
import clientInfoMixins from '@/mixins/clientInfoMixins';

export default {
  name: 'TopCard',
  props: {
    current: {
      type: String,
      default: '',
    },
    amount: Number,
    cover: {
      type: String,
      default: '',
    },
    progressHidden: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [clientInfoMixins],

  computed: {
    progress() {
      if (this.current === 'PersonalDetails') {
        return 0;
      }
      if (this.current === 'Beneficiaries') {
        return 24;
      }
      if (this.current === 'HealthQuestionnaire') {
        return 49;
      }
      if (this.current === 'ProposalSummary') {
        return 74;
      }
      if (this.current === 'PaymentOptions') {
        return 80;
      }
      return 100;
    },
    coverType() {
      // eslint-disable-next-line no-nested-ternary
      return this.cover;
    },
  },
  filters: {
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
</script>
<style lang="scss">
$stepper-header-elevation: 0;
.v-stepper {
  box-shadow: none !important;
}
</style>
