// eslint-disable-next-line import/prefer-default-export
export const allProducts = () => [
  {
    icon: '/images/icons/pumzisha.png',
    title: 'Pumzisha Plan',
    desc: 'Making plans ahead of time helps cushion yourself and your loved ones',
    link: '#',
    productCode: 2013105,
    display: false,
  },
  {
    icon: '/images/icons/elimu.png',
    title: 'Elimu Plan',
    desc: 'We work hard now to ensure the future of our little ones remain bright',
    link: { name: 'ElimuPlan' },
    productCode: 2013103,
    optionCode: 2012147,
    coverCode: [{ DefaultCover: 2013517 }, { TPD: 2013518 }, { WaiverOfPremium: 2013517 }, { MedicalReimbursement: 2013521 }, { MedicalReimbursement_child: 2013568 }, { CriticalIllness: 2013520 }, { Retrenchment: 2016611 }],
    display: true,
  },
  {
    icon: '/images/icons/imarika.png',
    title: 'Imarika Plan',
    desc: 'What is a better start to realizing your dreams for the future',
    link: { name: 'Imarika' },
    productCode: 2013104,
    optionCode: 2012146,
    coverCode: [{ Accidental: 2013513 }, { TPD: 2013512 }, { WaiverOfPremium: 2013511 }, { MedicalReimbursement: 2013515 }, { CriticalIllness: 2013514 }, { Retrenchment: 2016612 }],
    display: true,
  },
  {
    icon: '/images/icons/akiba.png',
    title: 'Akiba Halisi Plan',
    desc: 'Anticipating the unfortunate events we are here to enable you to build a fund over a period of time',
    link: { name: 'AkibaPlan' },
    productCode: 2013102,
    optionCode: [{ option_6: 2016206 }, { option_9: 2016207 }, { option_12: 2012141 }, { option_15: 2012142 }, { option_18: 2012143 }],
    coverCode: [{ Accidental: 2013497 }, { TPD: 2013496 }, { WaiverOfPremium: 2013495 }, { MedicalReimbursement: 2013499 }, { CriticalIllness: 2013498 }, { Retrenchment: 2016609 }],
    display: true,
  },
  {
    icon: '/images/icons/term.png',
    title: 'Term Assurance Plan',
    desc: 'This just got better we’ll enable you to have a life cover that can be used as security on a loan.',
    link: { name: 'TermAssurancePlan' },
    productCode: 2016194,
    display: true,
  },
  {
    icon: '/images/icons/wealth.png',
    title: 'Wealth Builder',
    desc: 'What is a better than to invest and cover your family at the same time?',
    link: { name: 'WealthBuilderPlan' },
    productCode: 2020196,
    display: true,
  },
  {
    icon: '/images/icons/whole.png',
    title: 'Whole Life',
    desc: 'The future is quite unsure but your family is certainly forever',
    link: { name: 'WholeLifePlan' },
    productCode: 2021200,
    display: true,
  },
  {
    icon: '/images/icons/keyman.png',
    title: 'Key Man',
    desc: 'APA Life understands that you need to safeguard your company',
    link: { name: 'KeymanPlan' },
    productCode: 2021197,
    display: true,
  },
  {
    icon: '/images/icons/individualpensionplan.png',
    title: 'Individual Pension Plan',
    desc: 'Retirement is not the end of the road it is the beginning of an open highway.',
    link: { name: 'IndividualPensionPlan' },
    productCode: 2013106,
    display: true,
  },
  {
    icon: '/images/icons/pumzisha.png',
    title: 'Pumzisha Plan',
    desc: 'Making plans ahead of time helps cushion yourself and your loved ones',
    link: { name: 'PumzishaPlan' },
    productCode: 2013105,
    display: true,
  },
  // {
  //   icon: '/images/icons/wealth.png',
  //   title: 'Elimu Plus',
  //   desc: 'What is a better than to invest for your child?',
  //   link: { name: 'ElimuPlusPlan' },
  //   productCode: 2024205,
  //   display: true,
  // },
];
